
  import { Component, Vue } from "vue-property-decorator";
  import LzTool from "./components/Tool.vue";
  import LzButton from "@/components/Button.vue";
  import { apiPlatformFeatures } from "../../../../api";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  @Component({
    components: { LzTool, LzButton }
  })
  export default class StepTools extends Vue {
    tools = {
      causes: false,
      events: false,
      market: false,
      partners: false,
      donations: false,
      courses: false,
      volunteers: false,
      impact: false
    };

    loadingPostStepTools = false;

    @auth.State("id")
    public ongId!: string;

    @auth.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    private get disableStepToolsButton(): boolean {
      return (
        !Object.values(this.tools).some(value => value === true) ||
        this.loadingPostStepTools
      );
    }

    sendStepTools() {
      this.loadingPostStepTools = true;
      apiPlatformFeatures(this.ongId, this.tools)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("auth.onboarding.stepTools.notifications")
          });
          this.updateFeatures(this.tools);
        })
        .catch(err => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic"),
            duration: 20000
          });
          console.error(err);
        })
        .finally(() => {
          this.loadingPostStepTools = false;
        });
    }
  }
