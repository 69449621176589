
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { apiBrand } from "../../../../api";
  import toBase64 from "@/utils/toBase64";
  import { namespace } from "vuex-class";
  import { LZ_MODULES } from "@/config/modules";

  const auth = namespace("auth");

  @Component({
    components: { LzButton }
  })
  export default class StepBrand extends Vue {
    brandForm = {
      logo: {
        files: [{ file: null, name: "" }]
      },
      primaryColor: null,
      secondaryColor: null
    };
    loadingPostBrand = false;

    @auth.State("id")
    public ongId!: string;

    @auth.State("name")
    public orgName!: string;

    async onBrandSubmit() {
      this.loadingPostBrand = true;

      const logoToBase64 = (await toBase64(
        this.brandForm.logo.files[0].file
      )) as string;
      const logoTrimmed = logoToBase64;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {
        logo: logoTrimmed,
        name: this.orgName,
        primary_color_hex: this.brandForm.primaryColor,
        secondary_color_hex: this.brandForm.secondaryColor
      };
      apiBrand(this.ongId, data)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("auth.onboarding.stepBrand.successIdentity")
          });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
          console.error(err);
        })
        .finally(() => {
          this.loadingPostBrand = false;
        });
    }
  }
