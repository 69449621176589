
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { CheckIcon } from "vue-tabler-icons";

  @Component({
    components: { CheckIcon }
  })
  export default class Tool extends Vue {
    @Prop({ default: false })
    protected readonly checked!: boolean;

    protected get toolClasses(): object {
      return {
        "lz-tool--checked": this.checked
      };
    }
  }
