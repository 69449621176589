
  import { Component, Vue } from "vue-property-decorator";
  import LzStepper from "@/components/Stepper.vue";
  import LzBox from "@/components/Box.vue";
  import LzButton from "@/components/Button.vue";
  import LzStepWelcome from "./views/stepWelcome/index.vue";
  import LzStepTools from "./views/stepTools/index.vue";
  import LzStepBrand from "./views/stepBrand/index.vue";
  import LzStepSubscription from "./views/stepSubscription/index.vue";

  type step = "stepWelcome" | "stepTools" | "stepBrand" | "stepSubscription";

  @Component({
    components: {
      LzStepper,
      LzBox,
      LzButton,
      stepWelcome: LzStepWelcome,
      stepTools: LzStepTools,
      stepBrand: LzStepBrand,
      stepSubscription: LzStepSubscription
    }
  })
  export default class Onboarding extends Vue {
    private steps: Array<step> = [
      "stepWelcome",
      "stepTools",
      "stepBrand",
      "stepSubscription"
    ];
    private active: step = this.steps[0];

    private setActiveStep(step: step) {
      this.active = step;
    }

    private get currentStepIdx(): number {
      return this.steps.findIndex(step => step === this.active);
    }

    private get isFirstStep(): boolean {
      return this.currentStepIdx === 0;
    }

    private get isLastStep(): boolean {
      return this.currentStepIdx === this.steps.length - 1;
    }

    onBackClick(): void {
      if (!this.isFirstStep) {
        this.active = this.steps[this.currentStepIdx - 1];
      }
    }

    onNextClick(actionClick: Function): void {
      if (!this.isLastStep) {
        this.active = this.steps[this.currentStepIdx + 1];
        actionClick?.();
      }
    }
  }
